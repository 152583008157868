/**
 * @file RessourceObject_ToolInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component designed for inputting tool-related resources. It includes form fields for the tool's name, description, and category, with predefined options like "Tool" or "Equipment." The component manages user input, maintains state, and submits data to an API for storage. Visual feedback elements, such as loading spinners and success/failure icons, enhance the user experience. This component showcases effective form handling, state management, and API integration for streamlined resource management in the application.
 *<br/>Breakdown of the code - {@tutorial RessourceObject_ToolInput}
 * @module RessourceObject_ToolInput
 *
 **/

import React from "react";
import StringInput from "./StringInput";
import TextInput from "./TextInput";
import ListInput from "./ListInput";
import ActionsMenu from "../Misc/ActionsMenu";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Spinner } from "flowbite-react";
import { withTranslation } from "react-i18next";
import { MiscFunctions } from 'helper-functions-package';
import { Api, baseApiParams } from "my-api-client-package";

class RessourceObject_ToolInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      name: "",
      description: "",
      categoryOptions: [
        { label: this.props.t('general.equipment'), tag: "equipment" },
        { label: this.props.t('general.tool'), tag: "tool" },
        { label: this.props.t('general.pharmaceuticals'), tag: "pharmaceutical" },
      ],
      disposable: null,
      selectCategory: "",
      saveData: false,
      sendSuccess: null,
      api: new Api({
        baseUrl: process.env.REACT_APP_API_URL
      })
    };
  }

  emptyValue = {
    label: "",
    tag: "empty",
  };

  handleDescription = (string) => {
    this.setState({ description: string });
  };
  handleName = (string) => {
    this.setState({ name: string });
  };

  handleSendSuccess = () => {
    this.setState({ sendSuccess: true });
  };

  handleCatOptionChange = (e) => {
    this.setState({ selectCategory: e });
  };

  handleDisposable = (elem) => {
    this.setState({ disposable: elem });
  };

  handleSubmit = async (e) => {
    this.setState({ saveData: e });

    let data = {
      label: this.state.name,
      description: this.state.description,
      type: "ToolDto",
      category: this.state.selectCategory.tag,
    };

    if (this.state.selectCategory.tag == "tool") {
      data.disposable = this.state.disposable.tag === "disposable" ? true : false;
    }
    const res = await this.state.api.ressourceobject.addRessourceobject(data, baseApiParams());
    if (res.status === 200 || res.status === 201) {
      this.setState({
        saveData: false,
      });
      this.handleDescription("");
      this.handleName("");
      this.handleCatOptionChange("");
      this.handleDisposable(null);
      this.handleSendSuccess(true);
    } else {
      this.handleSendSuccess(false);
    }
  };

  onClickOutsideListener = () => { };

  componentWillUnmount() { }

  render() {
    return (
      <div>
        <div className="group-input-drop-down">
          <StringInput
            label={this.props.t('general.name')}
            setValue={this.handleName}
            value={this.state.name}
          />
          {!MiscFunctions.isNull(this.state.categoryOptions) ? (
            <div>
              <label class="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white input_label">
                {this.props.t('general.category')}
              </label>
              <ActionsMenu
                options={this.state.categoryOptions}
                onClick={(e) => {
                  this.handleCatOptionChange(e);
                }}
                icon={""}
                preSelection={this.state.selectCategory}
              />
            </div>
          ) : (
            <Spinner
              style={{ height: "30px" }}
              color="success"
              aria-label="loading categories"
              size="sm"
            />
          )}
        </div>
        {/* <ListInput 
                label="Room-Template"
                Elements={this.RoomTemplates.Elements}
                selectHandler={this.handleRoom}
            /> */}
        {this.state.selectCategory.tag === "tool" && (
          <ListInput
            label={this.props.t('general.disposable')}
            Elements={[{ "label": this.props.t('general.disposable'), "tag": "disposable" },
            { "label": this.props.t('general.not_disposable'), "tag": "non disposable" }]}
            selectHandler={this.handleDisposable}
            preSelection={!MiscFunctions.isNull(this.state.disposable) ? this.state.disposable : this.emptyValue}
            Ready={true}
          />)}
        <TextInput
          label={this.props.t('general.description')}
          rows="9"
          setValue={this.handleDescription}
          value={this.state.description}
        />

        <button
          onClick={() => {
            this.handleSubmit();
          }}
          className="content-admin-save-btn"
          disabled={!this.state.name || !this.state.description ? true : false}
        >
          {this.props.t('general.enter')}{" "}
          {this.state.saveData && (
            <Spinner
              color="success"
              aria-label="loading categories"
              size="sm"
            />
          )}
        </button>
        {this.state.sendSuccess === true && (
          <CheckBadgeIcon className="h-6 w-6" />
        )}
        {this.state.sendSuccess === false && (
          <XCircleIcon className="h-6 w-6" />
        )}
      </div>
    );
  }
}

export default withTranslation()(RessourceObject_ToolInput);
