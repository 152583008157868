import player_poster from "../../assets/img/player/player_poster.jpg";

class propertyCommentAnnotation {
    constructor(id, x, y, category, state, mainComment) {
        this.id = id;
        this.left = x;
        this.top = y;
        this.category = category;
        this.status = state;
        this.mainComment = mainComment;
    }
    changeState = (state) => {
        this.status = state;
    };
}

const handleSlowPause = (playerRef) => {
    if (playerRef.current) {
        const decrementRate = () => {
            const currentRate = playerRef.current.playbackRate();
            const currentVolume = playerRef.current.volume();

            if (currentRate > 0.1) {
                playerRef.current.playbackRate(currentRate - 0.05); // Decrease playback rate
            }

            if (currentVolume > 0.0) {
                playerRef.current.volume(Math.max(0, currentVolume - 0.05)); // Decrease volume
            }

            if (currentRate > 0.1 || currentVolume > 0.0) {
                setTimeout(decrementRate, 100); // Set timeout for smooth transition
            } else {
                playerRef.current.pause(); // Pause the video
                playerRef.current.playbackRate(1); // Reset playback rate
                playerRef.current.volume(1); // Reset volume for next play
            }
        };
        decrementRate();
    }
};


const loadCues = async (actCue, user) => {
    if (actCue && actCue.cues_ && actCue.cues_.length > 0) {
        const AnnotationArray = [];
        actCue.cues_.forEach((el) => {
            let dummy;
            dummy = JSON.parse(el.text);
            if (dummy && !dummy.error) {
                try {
                    let pos = dummy.position;
                    let category = dummy.category[0].type;
                    AnnotationArray.push(
                        new propertyCommentAnnotation(
                            dummy.id,
                            pos[0] * 100 + "%",
                            pos[1] * 100 + "%",
                            category,
                            true,
                            dummy
                        )
                    );
                } catch (error) {
                    console.log(error);
                }
            }
        });
        //   setpropertyCommentAnnotationArray(AnnotationArray);
        return await AnnotationArray
    } else {
        const AnnotationArray = [];
        return AnnotationArray;
        //   setpropertyCommentAnnotationArray(AnnotationArray);
    }
};


const videoJsOptions = (props, getVideoURL) => {
    return {
        preload: true,
        controls: true,
        responsive: true,
        bigPlayButton: false,
        fluid: true,
        poster: player_poster,
        inactivityTimeout: 0,
        sources: [
            {
                src:
                    props.patientVideo === true
                        ? props.workflow.getSelectedVideo[0].media
                        : getVideoURL,
                type: "video/mp4",
            },
        ],
        controlBar: {
            fullscreenToggle: false,
            pictureInPictureToggle: false,
            remainingTimeDisplay: false,
            volumePanel: true,
            currentTimeDisplay: false,
            timeDivider: false,
            durationDisplay: false,
        },
    }

};


const PlayerControl = {
    handleSlowPause,
    loadCues,
    videoJsOptions,
    propertyCommentAnnotation
}

export default PlayerControl