import { createSlice } from '@reduxjs/toolkit';

const interactionsSlice = createSlice({
    name: 'interactions',
    initialState: [], // Initial comment value
    reducers: {
        setVideoInteractions: (state, action) => action.payload, // Update video interactions
        clearVideoInteractions: () => [], // Clear video interactions
        deleteComment: (state, action) => {
            return state.filter(elem => elem.id !== action.payload);
        }
    },
});

export const { setVideoInteractions, clearVideoInteractions, deleteComment } = interactionsSlice.actions;

export default interactionsSlice.reducer;


