import React from "react";
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    IconButton,
    useDisclosure,
    TabPanel,
    TabPanels,
} from "@chakra-ui/react";
import { FaCommentAlt } from "react-icons/fa";
import PlayerSidebar from "./PlayerSidebar"; // Ensure correct import
import { useSelector } from 'react-redux';
import { Container, Stack, Tab, TabIndicator, TabList, Tabs } from '@chakra-ui/react'
import SegmentsForm from "./SegmentsForm.tsx";
const PlayerSidebarWrapper = ({
    SETTINGS,
    commentCreate,
    setCommentCreate,
    setClickPlayIconInSiderbar,
    UserObject,
    CompanyUserObject,
    playerRef,
    filterCategories,
    workflow,
    WorkflowPhases,
    WorkflowTasks,
    addComment,
    deleteComment,
    deleteCommentId,
    ToggleUpdate,
    getClickedComment,
    mainVideoID,
    loadTextTracks,
    setRessourcesUpdate,
    ressourcesUpdate,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useSelector(state => state.isMobile);
    const userRole = UserObject.role;
    const isSelectedSegment = useSelector(state => state.selectedSegment);
    const containerAnnotationRef = useSelector(state => state.refs.containerAnnotationRef);
    return (
        <Container className="player_sidebar_container" padding="0" >
            <Stack spacing="16" className="player_sidebar_wrapper" padding="0" height={containerAnnotationRef}>
                {!isMobile && (

                    <div className="player_sidebar pt-3 pr-6 pl-2">
                        <Tabs variant="indicator" padding="0" className="player_sidebar_tabs" minWidth="360px" maxWidth="360px">
                            {userRole && userRole.toLowerCase() === "admin" && <TabList padding="0" className="player_sidebar_tabs_list" gap="2">
                                <Tab borderColor="teal.500" borderStyle="solid" borderWidth="1px" borderRadius="md">Comments</Tab>
                                <Tab borderColor="teal.500" borderStyle="solid" borderWidth="1px" borderRadius="md" isDisabled={!isSelectedSegment}>Segments</Tab>
                            </TabList>}
                            <TabIndicator />
                            <TabPanels padding="0" className="player_sidebar_tabs_panels">
                                <TabPanel padding="0" className="player_sidebar_tabs_panel">
                                    {SETTINGS.PlayerSidebar && (
                                        <PlayerSidebar
                                            commentCreate={commentCreate}
                                            setCommentCreate={setCommentCreate}
                                            setClickPlayIconInSiderbar={setClickPlayIconInSiderbar}
                                            UserObject={UserObject}
                                            companyUsers={CompanyUserObject}
                                            player={playerRef}
                                            filterCategories={filterCategories}
                                            workflow={workflow}
                                            processSteps={WorkflowPhases}
                                            tasks={WorkflowTasks}
                                            addComment={addComment}
                                            deleteComment={deleteComment}
                                            deleteCommentId={deleteCommentId}
                                            UpdateSegments={() => ToggleUpdate()}
                                            getClickedComment={getClickedComment}
                                            mainVideoID={mainVideoID}
                                            loadTextTracks={loadTextTracks}
                                            setRessourcesUpdate={setRessourcesUpdate}
                                            ressourcesUpdate={ressourcesUpdate}
                                        />
                                    )}
                                </TabPanel>
                                {userRole && userRole.toLowerCase() === "admin" && <TabPanel padding="0" className="player_sidebar_tabs_panel">
                                    <SegmentsForm />
                                </TabPanel>}
                            </TabPanels>
                        </Tabs>
                    </div>

                )}

                {/* Mobile Drawer with Hamburger Icon */}
                {isMobile && (
                    <Stack padding="0" className="player_sidebar_container">

                        <IconButton
                            icon={<FaCommentAlt color={"#299d9cad"} />}
                            onClick={onOpen}
                            aria-label="Open Sidebar"
                            position="fixed"
                            top="10px"
                            right="10px"
                            zIndex="10"
                            backgroundColor={"#bce2dd7a"}
                        />

                        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                            <DrawerOverlay />
                            <DrawerContent>
                                <DrawerCloseButton />
                                <DrawerHeader>Player Sidebar</DrawerHeader>
                                <DrawerBody>
                                    <Tabs variant="underline" padding="0">
                                        <TabList>
                                            <Tab>Comments</Tab>
                                            <Tab>Segments</Tab>
                                        </TabList>
                                        <TabIndicator />
                                        <TabPanels padding="0">
                                            <TabPanel padding="0">
                                                {SETTINGS.PlayerSidebar && (
                                                    <PlayerSidebar
                                                        commentCreate={commentCreate}
                                                        setCommentCreate={setCommentCreate}
                                                        setClickPlayIconInSiderbar={setClickPlayIconInSiderbar}
                                                        UserObject={UserObject}
                                                        companyUsers={CompanyUserObject}
                                                        player={playerRef}
                                                        filterCategories={filterCategories}
                                                        workflow={workflow}
                                                        processSteps={WorkflowPhases}
                                                        tasks={WorkflowTasks}
                                                        addComment={addComment}
                                                        deleteComment={deleteComment}
                                                        deleteCommentId={deleteCommentId}
                                                        UpdateSegments={() => ToggleUpdate()}
                                                        getClickedComment={getClickedComment}
                                                        mainVideoID={mainVideoID}
                                                        loadTextTracks={loadTextTracks}
                                                        setRessourcesUpdate={setRessourcesUpdate}
                                                        ressourcesUpdate={ressourcesUpdate}
                                                    />
                                                )}
                                            </TabPanel>
                                            <TabPanel padding="0">
                                                <SegmentsForm />
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>

                    </Stack>
                )}


            </Stack>
        </Container >
    );
};

export default PlayerSidebarWrapper;