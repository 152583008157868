import { configureStore } from '@reduxjs/toolkit';
import tokenReducer from './tokenSlice';
import userReducer from './userSlice';
import videoReducer from './videoSlice';
import workflowlibraryReducer from './workflowlibrarySlice';
import commentReducer from './commentSlice';
import isMobileReducer from './isMobileSlice';
import interactionsReducer from './InteractionsSlice';
import segmentReducer from './segmentSlice';
import ProgressBarPositionReducer from './ProgressBarPosition';
import refReducer from './refSlice.ts';
const loggerMiddleware = (store) => (next) => (action) => {
    console.log('Dispatching action:', action);
    console.log(store.getState());
    return next(action);
};

const store = configureStore({
    reducer: {
        token: tokenReducer, // Top-level token state
        user: userReducer,   // Top-level user state
        video: videoReducer,
        workflowlibrary: workflowlibraryReducer,
        comment: commentReducer,
        isMobile: isMobileReducer,
        interactions: interactionsReducer,
        selectedSegment: segmentReducer,
        ProgressBarPosition: ProgressBarPositionReducer,
        refs: refReducer,
        // Add more reducers for other states if needed
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['selectedSegment/setSegment'],
                ignoredPaths: ['selectedSegment'],
            },
        }).concat(loggerMiddleware),
});

export default store;