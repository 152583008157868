/**
 * @file CommentCard.js
 * @created_date Monday, August 14, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description Thiscomponent is named CommentCard, which is designed for displaying and interacting with comment in a player interface.
 *<br/>Breakdown of the code - {@tutorial CommentCard}
 * @module CommentCard
 *
 **/

import React from "react";
import Avatar from "../Misc/Avatar";
import { Icon } from "../Misc/Icon";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions } from 'helper-functions-package';
import { withTranslation } from "react-i18next";
import { Box, Stack, Text, Tooltip } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import store from "../../store/store";

class CommentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: this.props.comment,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.comment !== this.props.comment) {
      this.setState({ comment: this.props.comment });
    }
  }

  // componentWillUnmount() {
  //   this.setState({ comment: null });
  // }

  renderCommentDropDown = () => {
    if (!this.state.showHideTextBlock) {
      return (
        // arrow-down-icon
        <div className="arrow-up-block">
          <span className="icon_up all-category-icon"></span>
        </div>
      );
    } else {
      return (
        // arrow-up-icon
        <div className="arrow-down-block">
          <span className="icon_down all-category-icon"></span>
        </div>
      );
    }
  };

  clickComment = (comment, player) => {
    this.props.getClickedComment(this.state.comment.id)
    MiscFunctionsLocal.gotoTimestamp(comment.getTimeStampSeconds, player);
    var intervalID = setInterval(function () {
      let check = document.getElementById("ann_" + comment.getID);
      if (!MiscFunctions.isNull(check)) {
        clearInterval(intervalID);
        check.classList.add("FadeInAndOut");

        check.addEventListener("animationend", function () {
          check.classList.remove("FadeInAndOut");
        });
      }
    }, 300);
  };

  hoverProcessStep = (process) => {
    let check = document.getElementById("process_" + process.getID);
    var intervalID = setInterval(function () {
      if (!MiscFunctions.isNull(check)) {
        clearInterval(intervalID);
        var i = 0;
        if (!check.classList.contains("FadeInAndOut_Border"))
          check.classList.add("FadeInAndOut_Border");
        else check.classList.remove("FadeInAndOut_Border");

        if (++i === 4) {
          window.clearInterval(intervalID);
          check.classList.remove("FadeInAndOut_Border");
        }
      }
    }, 300);
    check.classList.remove("FadeInAndOut_Border");
  };

  render() {
    const isMobile = store.getState().isMobile;
    return (
      <Stack
        className={`mt-3 comment_side_box left_side_border ${this.state.comment.category[0] !== undefined ? this.state.comment.category[0].icon + "_left_border" : ''} comment_side_box_transition`}
        key={this.state.comment.getID}
        onClick={() => {
          setTimeout(() => {
            this.setState((prevState) => ({
              showHideTextBlock: !prevState.showHideTextBlock,
            }));
          }, 300)
        }}
        style={{ minHeight: isMobile ? "95px" : "105px", minWidth: isMobile ? "auto" : "335px", maxWidth: isMobile ? "auto" : "335px" }}
      >
        <div
          className={"comment_card "}
          id={"comment_card_" + this.state.comment.getID}
        >
          <div className="comment_card_face">
            <div className="flex gap-2">
              <div className="comment_box_title_container">
                <Stack className="comment_box_title">

                  {/* <Tooltip content={this.state.comment.getHeadline} > */}
                  <Tooltip label={this.state.comment.getHeadline} aria-label='A tooltip'>
                    <Text fontSize={{ base: "12px", md: "15px" }}>{this.state.comment.getHeadline}</Text>
                  </Tooltip>
                </Stack>
              </div>
              {!isMobile && <Box className="comment_box_avatar_container" display={{ base: "none", md: "block" }}>
                <Avatar
                  avatar={this.state.comment.getUserAvatar}
                  name={this.state.comment.getUserName}
                />
              </Box>}
            </div>
            {!isMobile && <Box className="comment_box_avatar_container" display={{ base: "none", md: "block" }}>
              <div className="comment_card_sub_details">
                <div
                  className="comment_box_title_process_step"
                  onMouseEnter={() => {
                    // this.hoverProcessStep(this.props.process);
                  }}
                >
                  {this.props.process.getLabel}
                </div>
                <div className="comment_box_time">
                  {MiscFunctions.customTimeFormat(
                    Math.ceil(this.state.comment.getTimeStampSeconds)
                  )}
                </div>
                <div className="comment_box_username">
                  {this.state.comment.getUserName === "null null" ? this.props.UserObject.display : this.state.comment.getUserName}
                </div>
              </div>
            </Box>}
            <div className="flex comments_cats">
              {/* Rendering the categories of the comment */}
              {!isMobile && this.state.comment.getCategories.map((cat, j) => {
                return (
                  <Box className="comment_box_avatar_container" display={{ base: "none", md: "block" }}>
                    <Icon category={cat} key={j} />
                  </Box>
                );
              })}
              {/* Rendering the Play-Button */}
              <div
                className="flex icon_play_box"
                onClick={() => {
                  this.clickComment(this.state.comment, this.props.player);
                }}
              >
                {/* Play-Icon */}
                <span className="icon_play all-category-icon" onClick={() => {
                  this.props.setClickPlayIconInSiderbar(true);
                  this.props.setCommentCreate(false);
                }}></span>
              </div>

              {!isMobile && <Box className="comment_reply_box" display={{ base: "none", md: "block" }}>
                <span className="icon_comment_reply all-category-icon">
                  <span className="comment_reply_conter">
                    {this.state.comment.getNumberOfReplies}
                  </span>
                </span>
              </Box>}
              {this.renderCommentDropDown()}
            </div>
            {this.state.showHideTextBlock && (
              <div className="mt-3 flex" key={this.state.comment.id}>
                <div className="comment_box_text">
                  {MiscFunctionsLocal.formatCommentText(
                    this.state.comment.getContent
                  )}
                  <div className="comment_box_date_box">
                    <div className="comment_box_created">
                      {this.props.t('general.created') + ": " + this.state.comment.getDateCreatedFormat}
                    </div>
                    <div className="comment_box_changed">
                      {this.props.t('general.changed') + ": " + this.state.comment.getDateChangedFormat}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Stack>
    );
  }
}

export default withTranslation()(CommentCard);