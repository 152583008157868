import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Textarea,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Api, baseApiParams, SegmentDto, PhaseDto, TaskDto } from "my-api-client-package";
import React from 'react';
import { PhotoUpload } from '../Misc/PhotoUpload.tsx';

const SegmentsForm = (props: any) => {

    const selectedSegment = useSelector((state: { selectedSegment: SegmentDto | PhaseDto | TaskDto }) => state.selectedSegment);
    const [selectedSegmentState, setSelectedSegmentState] = useState<SegmentDto | PhaseDto | TaskDto | null>(null);
    const api = new Api();

    // Sync selectedSegmentState with selectedSegment whenever it changes
    useEffect(() => {
        if (selectedSegment) {
            setSelectedSegmentState(selectedSegment);
        }
    }, [selectedSegment]);

    const handleUpdateSegment = async (segment: PhaseDto | TaskDto) => {
        const newSegment: SegmentDto = {
            id: { id: segment.id, tag: "segment" },
            label: segment.label,
            description: segment.description,
            timestamp: { timestamp: String(segment.timestamp?.timestamp), tag: "datetimestamp" },
            duration: Number(segment.duration),
            video: segment.video,
            segmenttype: segment.segmenttype,
        };
        console.log("newSegment", newSegment);
        try {
            // const response = await api.segment.saveSegment(newSegment as SegmentDto, baseApiParams());
            const response = await api.segment.saveSegment(newSegment as SegmentDto | PhaseDto | TaskDto, baseApiParams())
            console.log("Segment updated successfully:", response);
        } catch (error) {
            console.error("Error updating segment:", error);
        }
    }

    const handleInputChange = (field: string, value: any) => {
        if (selectedSegmentState && selectedSegmentState[field] !== value) {
            setSelectedSegmentState((prevState: any) => ({ ...prevState, [field]: value }));
        }
    }

    return (
        <Box as="form" bg="bg.surface" boxShadow="sm" borderRadius="lg" {...props}>
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="segmentLabel">
                        <FormLabel>Label</FormLabel>
                        <Input
                            defaultValue={selectedSegmentState ? selectedSegmentState.label : ""}
                            onChange={(e) => handleInputChange('label', e.target.value)}
                        />
                    </FormControl>
                </Stack>
                <FormControl id="segmentDescription">
                    <FormLabel>Description</FormLabel>
                    <Textarea
                        rows={5}
                        resize="none"
                        defaultValue={selectedSegmentState ? selectedSegmentState.description : ""}
                        onChange={(e) => handleInputChange('description', e.target.value)}
                    />
                </FormControl>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="timestamp">
                        <FormLabel>Timestamp</FormLabel>
                        <Input
                            type="time"
                            step="1"
                            defaultValue={selectedSegmentState ? selectedSegmentState.timestamp?.timestamp : ""}
                            onChange={(e) => handleInputChange('timestamp', { timestamp: e.target.value, tag: "datetimestamp" })}
                        />
                    </FormControl>
                    <FormControl id="duration">
                        <FormLabel>Duration</FormLabel>
                        <Input
                            defaultValue={selectedSegmentState ? selectedSegmentState.duration : ""}
                            onChange={(e) => handleInputChange('duration', Number(e.target.value))}
                        />
                    </FormControl>
                </Stack>
                <FormControl id="photo" flexDirection="row" alignItems="center">
                    <FormLabel>Photo</FormLabel>
                    <PhotoUpload />
                </FormControl>
            </Stack>
            <Divider />
            <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button
                    type="button"
                    colorScheme="teal"
                    variant="outline"
                    onClick={() => handleUpdateSegment(selectedSegmentState || selectedSegment)} // Fallback to selectedSegment if state is null
                >
                    Update
                </Button>
            </Flex>
        </Box>
    );
}

export default SegmentsForm;
