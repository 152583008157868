import { createSlice } from '@reduxjs/toolkit';
const segmentSlice = createSlice({
    name: 'selectedSegment',
    initialState: null,
    reducers: {
        setSegment: (state, action) => action.payload,
        clearSegment: () => null,
    },
});

export const { setSegment, clearSegment } = segmentSlice.actions;

export default segmentSlice.reducer;
