'use client'

import React from 'react'
import { FileUploadRoot, FileUploadTrigger, FileUploadRootProps } from '@ark-ui/react'
import { Avatar, Button } from '@chakra-ui/react'
import { useState } from 'react'
// import { Avatar } from '@/components/ui/avatar'
// import type { FileUploadRootProps } from '@/components/ui/file-button'
import { useEnvironmentContext } from '@ark-ui/react'
// import { FileUploadRoot, FileUploadTrigger } from '@/components/ui/file-button'

interface PhotoUploadProps extends FileUploadRootProps {
    src?: string
}

export const PhotoUpload = (props: PhotoUploadProps) => {
    const { src: srcProp, ...rest } = props
    const [src, setSrc] = useState<string | undefined>(srcProp)
    const env = useEnvironmentContext()
    return (
        <FileUploadRoot
            accept="image/*"
            maxFileSize={3145728}
            onFileChange={(e) => {
                const win = env.getWindow()
                const reader = new win.FileReader()
                reader.onload = () => setSrc(reader.result as string)
                reader.readAsDataURL(e.acceptedFiles[0])
            }}
            {...rest}
        >
            <Avatar size="xl" src={src} />
            <FileUploadTrigger asChild>
                <Button size="sm" variant="subtle">
                    Upload photo
                </Button>
            </FileUploadTrigger>
        </FileUploadRoot>
    )
}
