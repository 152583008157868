import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import store from '../../store/store'
import { MiscFunctions } from 'helper-functions-package';
class CommentAnchor extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.isMobile = store.getState().isMobile;
    }

    setClickedComment = (el) => {
        store.dispatch({ type: 'comment/setClickedComment', payload: MiscFunctions.isUndefined(el.mainComment) ? null : el.mainComment })
    }
    render() {
        return (
            <div className="comment-anchor-block" data-test={`comment-anchor-block-test-${this.props.elem.id}`} style={{ left: this.props.elem.left, top: this.props.elem.top }} >
                <span className={`${!this.isMobile ? 'comment-anchor-circle-hover' : ''} comment-anchor-circle ${this.props.elem.category}-location-color`}
                    data-test="comment-anchor"
                    onClick={() => {
                        this.props.getClickedComment(this.props.elem.id)
                        this.props.closeCommentCreate()
                    }}>

                </span>
            </ div>
        )
    }
}

export default withTranslation()(CommentAnchor)